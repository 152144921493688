<template>
  <div class="card card-danger">
    <div class="card-header">
      <h4>
        <small>Add</small> Teams
      </h4>
      <div class="card-header-action">
        <div class="card-header-form">

          <div class="input-group">
            <input type="text" class="form-control" placeholder="Search" v-model="seek" @keydown.enter="search">
            <div class="input-group-btn">
              <button class="btn btn-primary" @click.prevent="search"><i class="fas fa-search"></i></button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="card-body">

      <div class="table-responsive">
        <table class="table table-striped table-md">
          <tbody>
          <tr>
            <th>Team</th>
            <th class="text-center">Action</th>
          </tr>
          <tr v-for="(team, index) in getVuexTeams().data" :key="index">

            <td>{{ team.name }}</td>
            <td class="text-center">
              <a href="#" class="btn btn-primary btn-sm" @click.prevent="addTeam(index)">
                <i class="fa fa-plus"></i>
              </a>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
      <div class="card-footer text-right">
        Showing {{ getVuexTeams().to }} from {{ getVuexTeams().total }} Records
        <nav class="d-inline-block">
          <ul class="pagination mb-0">
            <li class="page-item" :class="{'disabled': getVuexTeams().current_page === 1}">
              <a class="page-link" href="#" tabindex="-1"
                 @click.prevent="show(getVuexTeams().current_page -1)">
                <i class="fas fa-chevron-left"></i>
              </a>
            </li>

            <li class="page-item" :class="{'disabled': getVuexTeams().current_page === getVuexTeams().last_page}">
              <a class="page-link" href="#" @click.prevent="show(getVuexTeams().current_page + 1)"><i
                  class="fas fa-chevron-right"></i></a>
            </li>
          </ul>
        </nav>
      </div>


    </div>

  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import {show} from '@/services/manageTeams/Teams';
import {searchTeam} from '@/services/setTournament/Tournament';
import {useToast} from 'vue-toastification';

export default {

  data() {
    return {
      toast: useToast(),
      seek: ''
    }
  },

  methods: {
    ...mapGetters({
      getTournament: 'SetTournament/getTournament',
      getSelectedTeams: 'SetTournament/getSelectedTeams',
      getVuexTeams: 'SetTournament/getTeams',
    }),

    ...mapMutations({
      setSelectedTeams: 'SetTournament/setSelectedTeams',
      setAllSelectedTeams: 'SetTournament/setAllSelectedTeams',
      setTeams: 'SetTournament/setTeams',
      setTeamsData: 'SetTournament/setTeamsData',
      removeSetTeam: 'SetTournament/removeSetTeams',
    }),

    getTeams(page = 1) {

      let id = [];


      for (let i = 0; i < this.getSelectedTeams().length; i++) {
        if (this.getSelectedTeams()[i].team_tournament_id) {
          id.push(this.getSelectedTeams()[i].id);
        }

      }


      show(page, 1, id, this.getTournament().id).then(response => {

        //this.teams = response.data;

        this.setAllSelectedTeams(response.data.selected_teams);

        this.setTeams(response.data.teams);

      });

    },


    search() {


      this.toast.info('Searching');

      let data = {
        seek: this.seek,
        tournament_id: this.getTournament().id,
      };

      searchTeam(data).then(response => {
        this.toast.clear();
        this.setTeamsData(response.data);
      }, error => {
        this.toast.clear();
        this.toast.error(error.response.data.message);
      });

    },

    addTeam(index) {

      this.setSelectedTeams(this.getVuexTeams().data[index]);
      this.removeSetTeam(index);

    },


  },

  mounted() {
    this.getTeams();
  }
}

</script>
<style scoped>

</style>