<template>
  <div class="card card-primary">
    <div class="card-header">
      <h4>Tournaments</h4>
      <div class="card-header-action">
        <a href="#" class="btn btn-primary" @click.prevent="show = !show">
          <i class="fa fa-angle-down" v-if="!show"></i>
          <i class="fa fa-angle-up" v-else></i>
        </a>
      </div>
    </div>
    <div class="card-body">
      <transition enter-active-class="animate__animated animate__fadeIn"

      >
        <div class="row" v-if="show">
          <div class="col-12 col-md-6">
            <img v-if="tournament.id" :src="$store.state.Global.rootCDN + tournament.logo" :alt="tournament.name"
                 style="height: 90px;">
            <form action="#" method="post" enctype="multipart/form-data" @submit.prevent="store" id="tournamentForm">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Tournament Name</label>
                    <input type="text" class="form-control" name="name" v-model="tournament.name">
                    <input type="hidden" class="form-control" name="id" v-model="tournament.id" v-if="tournament.id">
                    <span class="text text-danger" v-if="validationErrors.name">
                      {{ validationErrors.name[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Logo</label>
                    <input type="file" class="form-control" name="logo">
                    <input type="hidden" class="form-control" name="old_logo" v-model="tournament.logo"
                           v-if="tournament.id">
                    <span class="text text-danger" v-if="validationErrors.logo">
                      {{ validationErrors.logo[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Slogan</label>
                    <input type="text" class="form-control" name="slogan" v-model="tournament.slogan">
                    <span class="text text-danger" v-if="validationErrors.slogan">
                      {{ validationErrors.slogan[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Details</label>
                    <input type="text" class="form-control" name="details" v-model="tournament.details">
                    <span class="text text-danger" v-if="validationErrors.details">
                      {{ validationErrors.details[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Start Date</label>
                    <input type="date" class="form-control" name="start_date" v-model="tournament.start_date">
                    <span class="text text-danger" v-if="validationErrors.start_date">
                      {{ validationErrors.start_date[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>End Date</label>
                    <input type="date" class="form-control" name="end_date" v-model="tournament.end_date">
                    <span class="text text-danger" v-if="validationErrors.end_date">
                      {{ validationErrors.end_date[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Type</label>
                    <select class="form-control" name="tournament_type_id">
                      <option value="">--Select--</option>
                      <option v-for="(type, index) in tournamentTypes" :key="index" :value="type.id"
                              :selected="type.id === tournament.tournament_type_id">
                        {{ type.name }}
                      </option>
                    </select>
                    <span class="text text-danger" v-if="validationErrors.tournament_type_id">
                      {{ validationErrors.tournament_type_id[0] }}
                    </span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Entity</label>
                    <select class="form-control" name="entity_id">
                      <option value="">--Select--</option>
                      <option v-for="(entity,index) in entities" :value="entity.id" :key="index"
                              :selected="entity.id === tournament.entity_id">
                        {{ entity.name }}
                      </option>
                    </select>
                    <span class="text text-danger" v-if="validationErrors.entity_id">
                      {{ validationErrors.entity_id[0] }}
                    </span>

                  </div>
                </div>
                <div class="col-12 text-right">
                  <button type="reset" class="btn btn-danger mr-1" @click="tournament = {}">Cancel</button>
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </form>

          </div>
          <div class="col-12 col-md-6">
            <div class="card-header" style="display: block">
              <div class="card-header-form">
                <form method="post" @submit.prevent="">
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search" v-model="seek" name="seek"
                           @keydown.enter="search">
                    <div class="input-group-btn">
                      <button type="button" class="btn btn-primary" @click.prevent="search">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <app-loader v-if="loadingTournaments"/>
            <div class="row" v-else>
              <div class="col-12">
                <div class="table-responsive">

                  <table class="table table-striped table-md">
                    <tbody>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th class="text-center">Action</th>
                    </tr>
                    <tr v-for="(tournament,index) in tournaments.data" :key="index">
                      <td>

                        <div class="custom-checkbox custom-control">
                          <input type="radio" data-checkboxes="mygroup" class="custom-control-input"
                                 :id="'checkbox-'+index" name="tournament_id" :value="tournament.id"
                                 :checked="getTournament().id === tournament.id"
                                 @change="setSelectedTournament(tournament)">
                          <label :for="'checkbox-'+index" class="custom-control-label">&nbsp;</label>
                        </div>
                      </td>
                      <td>{{ tournament.name }}</td>
                      <td class="text-center">
                        <a href="#" class="btn btn-primary btn-sm mr-1" @click.prevent="setTournament(index)">Detail</a>
                        <a href="#" class="btn btn-danger btn-sm" @click.prevent="destroy(tournament.id)">Remove</a>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>

                <div class="text-right">
                  Showing {{ tournaments.to }} from {{ tournaments.total }} Records
                  <nav class="d-inline-block">
                    <ul class="pagination mb-0">
                      <li class="page-item" :class="{'disabled': tournaments.current_page === 1}">
                        <a class="page-link" href="#" tabindex="-1"
                           @click.prevent="showTournaments(tournaments.current_page -1)">
                          <i class="fas fa-chevron-left"></i>
                        </a>
                      </li>

                      <li class="page-item" :class="{'disabled': tournaments.current_page === entities.last_page}">
                        <a class="page-link" href="#" @click.prevent="showTournaments(tournaments.current_page + 1)"><i
                            class="fas fa-chevron-right"></i></a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>


          </div>
        </div>
      </transition>

    </div>

  </div>
</template>
<script>
import {getAllTypes} from "@/services/lookup/TournamentType";
import {getAllEntities} from "@/services/lookup/Entity";
import {destroy, search, show, store, update} from '@/services/setTournament/Tournament';
import {show as teamShow} from '@/services/manageTeams/Teams';
import {useToast} from 'vue-toastification';
import {mapGetters, mapMutations} from 'vuex';
import Loader from "@/components/layouts/parts/Loader";

export default {
  data() {
    return {
      tournamentTypes: [],
      tournaments: [],
      tournament: {},
      entities: [],
      show: true,
      toast: useToast(),
      validationErrors: [],
      seek: '',
      loadingTournaments: true,
    }
  },

  components: {
    appLoader: Loader,
  },

  methods: {
    ...mapMutations({
      vuexSetTournaments: 'SetTournament/setTournaments',
      vuexSetTournament: 'SetTournament/setTournament',
      setAllSelectedTeams: 'SetTournament/setAllSelectedTeams',
      setTeams: 'SetTournament/setTeams',
    }),
    ...mapGetters({
      getTournament: 'SetTournament/getTournament',
      getSelectedTeams: 'SetTournament/getSelectedTeams',
    }),
    getTeams(page = 1) {


      teamShow(page, 1, [], this.getTournament().id).then(response => {

        //this.teams = response.data;

        if (response.data.selected_teams.length > 0) {
          this.setAllSelectedTeams(response.data.selected_teams);
        }


        this.setTeams(response.data.teams);

      });

    },

    setSelectedTournament(tournament) {
      this.vuexSetTournament(tournament);
      this.getTeams();

    },

    getAllTypes() {
      getAllTypes().then(response => {
        this.tournamentTypes = response.data;
      })
    },
    getAllEntities() {
      getAllEntities().then(response => {
        this.entities = response.data;
      })
    },
    showTournaments(page = 1) {

      show(page).then(response => {
        this.tournaments = response.data;
        this.loadingTournaments = false;
        this.vuexSetTournaments(response.data.data);
      });
    },
    store() {
      let tournament = document.getElementById('tournamentForm');

      let tournamentFormData = new FormData(tournament);

      this.toast.info('processing...')
      if (!this.tournament.id) {
        store(tournamentFormData).then(() => {
          this.toast.clear();
          this.toast.success('Tournament Created Successfully');
          this.showTournaments(this.tournaments.current_page);
          this.validationErrors = {};
          this.tournament = {};
          tournament.reset();
        }, error => {
          this.validationErrors = {};
          this.toast.clear();
          this.toast.error(error.response.data.message);
          this.validationErrors = error.response.data.errors;
        });
      } else {
        update(tournamentFormData).then(() => {
          this.toast.clear();
          this.toast.success('Tournament Updated Successfully');
          this.showTournaments(this.tournaments.current_page);
          this.validationErrors = {};
          this.tournament = {};
          tournament.reset();
        }, error => {
          this.validationErrors = {};
          this.toast.clear();
          this.toast.error(error.response.data.message);
          this.validationErrors = error.response.data.errors;
        });
      }


    },
    setTournament(index) {
      this.tournament = this.tournaments.data[index];
    },
    destroy(id) {

      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: 'Yes, delete it!'

      }).then((result) => {

        if (result.value) {

          this.toast.info('deleting record!');

          destroy(id).then(() => {

            this.toast.clear();
            this.$swal(
                'Deleted!',

                'Record has been deleted.',

                'success'
            );

            this.showTournaments(this.tournaments.current_page);

          }, error => {

            this.toast.clear();
            this.toast.error(error.response.data.message)

          });


        }

      });

    },
    search() {
      this.toast.info('processing...');
      search(this.seek).then(response => {
        this.toast.clear();
        this.tournaments.data = response.data;
      }, error => {
        this.toast.error(error.response.data.message);
      });
    },
  },

  mounted() {
    this.getAllTypes();
    this.getAllEntities();
    this.showTournaments();
  }
}
</script>
<style scoped>

</style>