<template>
  <div class="card card-warning">
    <div class="card-header">
      <h4>
        <small>to</small> Tournament <small> {{ getTournament().name }} </small>
      </h4>
      <div class="card-header-action">

      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-striped table-md">
          <tbody>
          <tr>
            <th></th>
            <th>Team</th>
            <th class="text-center">Action</th>
          </tr>
          <tr v-for="(team, index) in getSelectedTeams()" :key="index">

            <td>
              <div class="custom-checkbox custom-control">

                <input type="radio"
                       data-checkboxes="myteamgroup"
                       class="custom-control-input"
                       :id="'checkboxteam-'+index" name="team_id"
                       :value="team.id"
                       :checked="getSelectedTeam().id === team.id"
                       @change="setSelectedTeam(team)"
                >
                <label :for="'checkboxteam-'+index" class="custom-control-label">&nbsp;</label>

              </div>
            </td>
            <td>{{ team.name }}</td>
            <td class="text-center">
              <a href="#" class="btn btn-danger btn-sm" @click.prevent="removeTeam(team,index)">
                <i class="fa fa-minus"></i>
              </a>
            </td>
          </tr>

          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-12">
          <button class="btn btn-primary btn-sm btn-block" @click.prevent="store" v-if="getSelectedTeams().length > 0">
            Save
          </button>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import {destroyTeam, storeTeamTournament} from '@/services/setTournament/Tournament';
import {useToast} from 'vue-toastification';
import {playerMixin} from "@/mixins/setTournament/Player";


export default {
  mixins: [playerMixin],
  data() {
    return {
      toast: useToast(),
    }
  },

  methods: {
    ...mapGetters({
      getTournament: 'SetTournament/getTournament',
      getSelectedTeams: 'SetTournament/getSelectedTeams',
      getSelectedTeam: 'SetTournament/getSelectedTeam',
    }),

    ...mapMutations({
      removeSetSelectedTeam: 'SetTournament/removeSetSelectedTeam',
      addToSetTeams: 'SetTournament/addToSetTeams',
      vuexSetSelectedTeam: 'SetTournament/setSelectedTeam',
    }),

    removeTeam(team, index) {


      let data = {
        tournament_id: this.getTournament().id,
        team_id: team.id,
      };


      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: 'Yes, delete it!'

      }).then((result) => {

        if (result.value) {

          this.toast.info('deleting record!');

          destroyTeam(data).then(() => {
            this.toast.clear()
            this.removeSetSelectedTeam(index);
            this.addToSetTeams(team);
          }, error => {
            this.toast.clear();
            this.toast.error(error.response.data.message)
          });


        }

      });


    },
    store() {

      let data = {
        tournament_id: this.getTournament().id,
        teams: [],
      };

      let teams = this.getSelectedTeams();

      for (let i = 0; i < teams.length; i++) {
        data.teams.push(teams[i].id);

      }

      this.toast.info('processing...')
      storeTeamTournament(data).then(() => {
        this.toast.clear();
      }, error => {
        this.toast.clear();
        this.toast.error(error.response.data.message);
      });

    },
    setSelectedTeam(team) {

      this.vuexSetSelectedTeam(team);

      this.getPlayers();

    }
  },

  mounted() {

  }
}
</script>
<style scoped>

</style>