<template>
  <div class="section mb-5">

    <head-slot>
      <title>Scoring And Performance | Set Tournament</title>
    </head-slot>

    <div class="section-header">

      <h1>Scoring and Performance</h1>


      <div class="section-header-breadcrumb">

        <div class="breadcrumb-item active">
          <a href="#" @click.prevent="">Scoring and Performance</a>
        </div>

        <div class="breadcrumb-item">Set Tournament</div>

      </div>

    </div>

    <div class="section-body">
      <div class="row">
        <div class="col-12">
          <app-tournament/>
        </div>
      </div>
      <div class="row" v-if="getTournament().id">
        <div class="col-12 col-md-6">
          <app-team/>
        </div>
        <div class="col-12 col-md-6">
          <app-tournament-team/>
        </div>
      </div>
      <div class="row" v-if="getSelectedTeam().id">
        <div class="col-12 col-md-6">
          <app-player/>
        </div>
        <div class="col-12 col-md-6">
          <app-player-team/>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Tournament from "@/components/scoringAndPerformance/setTournament/parts/Tournament";
import TournamentTeam from "@/components/scoringAndPerformance/setTournament/parts/TournamentTeam";
import Team from "@/components/scoringAndPerformance/setTournament/parts/Team";
import Player from '@/components/scoringAndPerformance/setTournament/parts/Player';
import PlayerTeam from "@/components/scoringAndPerformance/setTournament/parts/PlayerTeam";
import {mapGetters} from 'vuex';

export default {
  components: {
    appTournament: Tournament,
    appTournamentTeam: TournamentTeam,
    appTeam: Team,
    appPlayer: Player,
    appPlayerTeam: PlayerTeam,
  },
  methods: {
    ...mapGetters({
      getTournament: 'SetTournament/getTournament',
      getSelectedTeam: 'SetTournament/getSelectedTeam',
    }),
  }
}
</script>
<style>

</style>