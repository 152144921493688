<template>
  <div class="card card-dark">
    <div class="card-header">
      <h4>
        <small>to</small> Team <small>{{ getSelectedTeam().name }}</small>
      </h4>

    </div>
    <div class="card-body">

      <app-loader v-if="vuexGetSelectedPlayers().length < 0"/>

      <div class="row" v-else>
        <div class="col-12">
          <div class="table-responsive">

            <table class="table table-striped table-md">
              <tbody>
              <tr>
                <th>Photo</th>
                <th>Player</th>
                <th class="text-center">Action</th>
              </tr>
              <tr v-for="(player,index) in vuexGetSelectedPlayers()" :key="index">
                <td class="align-middle">
                  <img alt="image" :src="$store.state.Global.rootCDN + player.photo" class="rounded-circle" width="35"
                       data-toggle="tooltip"
                       :title="player.en_first_name" :data-original-title="en_first_name">

                </td>
                <td>
                  {{ player.en_first_name + ' ' + player.en_last_name }}
                </td>
                <td class="text-center">
                  <a href="#" class="btn btn-danger btn-sm"
                     @click.prevent="removePlayer(player,player.team_tournament_id,index)">
                    <i class="fa fa-minus"></i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>

          </div>

          <div class="row" v-if="vuexGetSelectedPlayers().length > 0">
            <div class="col-12">
              <button class="btn btn-primary btn-sm btn-block" @click.prevent="store">Save</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {useToast} from 'vue-toastification';
import {destroyPlayer, storePlayerTeam} from "@/services/setTournament/Tournament";
import Loader from "@/components/layouts/parts/Loader";
import {playerMixin} from "@/mixins/setTournament/Player";

export default {

  data() {
    return {
      toast: useToast(),
    }
  },
  mixins: [playerMixin],
  components: {
    appLoader: Loader,
  },
  methods: {
    ...mapGetters({
      getSelectedTeam: 'SetTournament/getSelectedTeam',
      vuexGetPlayers: 'SetTournament/getPlayers',
      vuexGetSelectedPlayers: 'SetTournament/getSelectedPlayers',


    }),
    ...mapMutations({
      addToPlayers: 'SetTournament/addToPlayers',
      removeFromSelectedPlayers: 'SetTournament/removeFromSelectedPlayers',
    }),


    store() {

      let data = {
        team_tournament_id: this.getSelectedTeam().team_tournament_id,
        players: [],
      };

      let players = this.vuexGetSelectedPlayers();

      for (let i = 0; i < players.length; i++) {
        data.players.push(players[i].id);

      }

      console.log(data);

      this.toast.info('processing...')

      storePlayerTeam(data).then(() => {
        this.toast.clear();
      }, error => {
        this.toast.clear();
        this.toast.error(error.response.data.message);
      });

    },

    removePlayer(player, team_tournament_id, index) {


      let data = {
        team_tournament_id: team_tournament_id,
        player_id: player.id,
      };


      this.$swal({

        title: 'Are you sure?',

        text: "You won't be able to revert this!",

        type: 'warning',

        showCancelButton: true,

        confirmButtonColor: '#d33',

        cancelButtonColor: '#3085d6',

        confirmButtonText: 'Yes, delete it!'

      }).then((result) => {

        if (result.value) {

          this.toast.info('deleting record!');

          destroyPlayer(data).then(() => {
            this.toast.clear()
            this.addToPlayers(player);
            this.removeFromSelectedPlayers(index);
          }, error => {
            this.toast.clear();
            this.toast.error(error.response.data.message)
          });


        }

      });
    }
  },
}
</script>