import API from '../API';

export function getTechnicalInfo() {
    return API.get('/manage-players/get-technical-info');
}

export function store(data) {

    return API.post('/manage-players/players/store', data);

}

export function show(page, tournament_id = 0, player_id = [], team_id = 0) {
    return API.post('/manage-players/players/show', {page, tournament_id, player_id, team_id});
}

export function destroy(id) {
    return API.post('/manage-players/players/destroy',
        {
            id
        }
    );
}

export function get(id) {
    return API.get('/manage-players/players/get?id=' + id);
}

export function search(seek) {
    return API.get('/manage-players/players/search?seek=' + seek);
}