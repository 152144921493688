import API from '../API';

export function store(data) {
    return API.post('set-tournament/tournament/store', data);
}

export function update(data) {
    return API.post('set-tournament/tournament/update', data);
}

export function show(page) {
    return API.get('set-tournament/tournament/show?page=' + page);
}

export function destroy(id) {
    return API.post('set-tournament/tournament/destroy', {id});
}

export function search(seek) {
    return API.get('set-tournament/tournament/search?seek=' + seek);
}

export function searchTeam(data) {
    return API.post('set-tournament/team-tournament/search-team', data);
}


export function storeTeamTournament(data) {
    return API.post('set-tournament/team-tournament/store', data);
}

export function destroyTeam(data) {
    return API.post('set-tournament/team-tournament/destroy', data);
}

export function destroyPlayer(data) {
    return API.post('set-tournament/player-team/destroy', data);
}


export function storePlayerTeam(data) {
    return API.post('set-tournament/player-team/store', data);
}

export function searchPlayer(data) {
    return API.post('set-tournament/player-team/search-player', data);
}


