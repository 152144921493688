import {show} from "@/services/managePlayers/Players";
import {mapGetters, mapMutations} from 'vuex';

export const playerMixin = {
    data() {
        return {

            loadingPlayers: true,
        }
    },

    methods: {
        ...mapGetters({
            getSelectedPlayers: 'SetTournament/getSelectedPlayers',
            getTournament: 'SetTournament/getTournament',
            getSelectedTeam: 'SetTournament/getSelectedTeam',
        }),
        ...mapMutations({
            setAllSelectedPlayers: 'SetTournament/setAllSelectedPlayers',
            setPlayers: 'SetTournament/setPlayers',
        }),

        getPlayers(page = 1) {

            this.loadingPlayers = true;

            let player_id = [];


            for (let i = 0; i < this.getSelectedPlayers().length; i++) {
                if (this.getSelectedPlayers()[i].id) {
                    player_id.push(this.getSelectedPlayers()[i].id);
                }

            }


            show(page, this.getTournament().id, player_id, this.getSelectedTeam().id).then(response => {

                this.loadingPlayers = false;

                this.setPlayers(response.data.players);

                this.setAllSelectedPlayers(response.data.selected_players);

            });


        },
    },


}