<template>
  <div class="card card-dark">
    <div class="card-header">
      <h4>
        <small>Add</small> Players
      </h4>
      <div class="card-header-action">
        <div class="card-header-form">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Search" v-model="seek" @keydown.enter="searchPlayer">
            <div class="input-group-btn">
              <button class="btn btn-primary" @click="searchPlayer"><i class="fas fa-search"></i></button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <app-loader v-if="loadingPlayers"/>
      <div class="row" v-else>
        <div class="col-12">
          <div class="table-responsive">

            <table class="table table-striped table-md">
              <tbody>
              <tr>
                <th>Photo</th>
                <th>Player</th>
                <th class="text-center">Action</th>
              </tr>
              <tr v-for="(player,index) in vuexGetPlayers().data" :key="index">
                <td class="align-middle">
                  <img alt="image" :src="$store.state.Global.rootCDN + player.photo" class="rounded-circle" width="35"
                       data-toggle="tooltip"
                       :title="player.en_first_name" :data-original-title="en_first_name">

                </td>
                <td>{{ player.en_first_name + ' ' + player.en_last_name }}</td>
                <td class="text-center">
                  <a href="#" class="btn btn-primary btn-sm" @click.prevent="setSelectedPlayers(player, index)">
                    <i class="fa fa-plus"></i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>

          </div>

          <div class="card-footer text-right">
            Showing {{ vuexGetPlayers().to }} from {{ vuexGetPlayers().total }} Records
            <nav class="d-inline-block">
              <ul class="pagination mb-0">
                <li class="page-item" :class="{'disabled': vuexGetPlayers().current_page === 1}">
                  <a class="page-link" href="#" tabindex="-1"
                     @click.prevent="getPlayers(vuexGetPlayers().current_page -1)">
                    <i class="fas fa-chevron-left"></i>
                  </a>
                </li>

                <li class="page-item"
                    :class="{'disabled': vuexGetPlayers().current_page === vuexGetPlayers().last_page}">
                  <a class="page-link" href="#" @click.prevent="getPlayers(vuexGetPlayers().current_page + 1)"><i
                      class="fas fa-chevron-right"></i></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>

import {mapGetters, mapMutations} from 'vuex';
import {playerMixin} from "@/mixins/setTournament/Player";
import {searchPlayer} from "@/services/setTournament/Tournament";
import Loader from "@/components/layouts/parts/Loader";

export default {
  components: {
    appLoader: Loader,

  },
  data() {
    return {
      seek: '',
    }
  },
  mixins: [playerMixin],
  methods: {
    ...mapMutations({
      setPlayers: 'SetTournament/setPlayers',
      setPlayersData: 'SetTournament/setPlayersData',
      vuexSetSelectedPlayers: 'SetTournament/setSelectedPlayers',
      removeFromPlayers: 'SetTournament/removeFromPlayers',
    }),
    ...mapGetters({
      vuexGetPlayers: 'SetTournament/getPlayers',
      getSelectedPlayers: 'SetTournament/getSelectedPlayers',
      getTournament: 'SetTournament/getTournament',
    }),


    setSelectedPlayers(player, index) {

      this.removeFromPlayers(index);
      this.vuexSetSelectedPlayers(player);
    },

    searchPlayer() {

      this.loadingPlayers = true;
      let data = {
        seek: this.seek,
        tournament_id: this.getTournament().id
      };


      searchPlayer(data).then(response => {

        this.setPlayersData(response.data);
        this.loadingPlayers = false;
      });
    },

  },
  mounted() {
    this.getPlayers()
  }
}
</script>